@import "../styles/variables.scss";


.footer-section {
    position: relative;
    height: 100vh;
    min-width: $min-width;
    overflow: hidden;
    margin-top: 1px;
}

.contact-area {
    position: absolute;
    bottom: 0;
    width: 100vw;
    min-width: $min-width;
    background-color: $bg-color-dark;
    transition: ease 200ms;

    .contact-text-wrapper {
        br {
            display: inline-block;
        }

        h1 {
            font-weight: 400;
            letter-spacing: 0.15em;
        }

        p {
            font-style: italic;
            line-height: 1.4em;
        }
    }
}

.std-button {
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .1em;
    font-weight: 600;
    color: $bg-color;
    background-color: $second-accent-color;
    display: block;
    white-space: nowrap;
    clear: both;
    cursor: pointer;
    padding: .5em 1.7em .5em 1.5em;

    line-height: 2.5em;
    margin-top: 1em;

    .bi {
        padding: 0;
        display: inline;
        vertical-align: middle;
        font-size: 220%;
    }

    .left-icon {
        font-size: 150%;
        vertical-align: middle;
        padding-left: .2em;
        padding-right: .5em;
    }
}

.std-button:hover {
    color: $bg-color;
    background-color: $second-accent-color-light;
}

.std-button:active {
    background-color: $text-color;
}


.footer-links {
    padding: 2.5em 0 2.8em 0;

    .social-icons {
        display: inline;
        font-size: 220%;
        padding: 0;
        vertical-align: middle;
        margin-right: .5em;
        cursor: pointer;
        transition: ease 150ms;
    }

    .social-icons:hover {
        color: $second-accent-color-light;
    }

    .social-icons:active {
        color: $text-color;
    }

    .footer-nav-wrapper {
        padding-top: 1em;


        .footer-nav,
        .footer-nav:link {
            display: inline-block;
            text-transform: uppercase;
            text-decoration: none;
            letter-spacing: .1em;
            font-weight: 500;
            transition: ease 150ms;
            color: $text-color;

            margin-top: 3em;
            font-size: .6em;
        }

        .divider {
            opacity: .5;
            margin: 0 .5em 0 .5em;
        }

    }

    .footer-nav-active:hover,
    .footer-nav-active:link:hover {
        color: $second-accent-color-light;
    }

    .footer-nav-active:link:active {
        color: $text-color;
    }
}

@media only screen and (max-width: $break-sm) {

    .footer-section {
        height: $min-width *2.25;
    }

    .contact-area {
        width: 100%;
        min-width: 100%;
        padding: 1.5em;

        .contact-text-wrapper {
            width: 100%;
            padding-bottom: .5em;

            h1 {
                font-size: 1.2em * 1.6;
            }

            p {
                margin: 1em 0 1em 0;
                font-size: 1.2em;
            }
        }


    }

    .std-button {
        font-size: .65em;
    }

    .footer-links {
        margin-bottom: 2em;

    }
}

@media only screen and (min-width: $break-sm) {
    .contact-area {
        width: 100%;
        padding: 4vw 4vw 0 4vw;

        .contact-text-wrapper {
            min-width: $min-width;
            padding-bottom: .5em;

            h1 {
                width: 100%;
                font-size: 1.2em * 1.6;
            }

            p {
                width: 90%;
                max-width: $min-width * 1.5;
                font-size: 1.2em;
                margin: 2em 0 1em 0;
            }
        }


    }

    .std-button {
        font-size: .7em;
    }

}

@media only screen and (min-width: $break-md) {
    .contact-area {
        width: 100%;
        padding: 4vw 4vw 0 4vw;

        .contact-text-wrapper {
            padding-bottom: .5em;

            h1 {
                width: 90%;
                font-size: 1.3em * 1.6;
            }

            p {
                width: 70%;
                font-size: 1.3em;
                margin: 2em 0 1em 0;
            }
        }


    }

    .std-button {
        font-size: .7em;
    }
}

@media only screen and (min-width: $break-lg) {
    .contact-area {
        width: 100%;
        padding: 5vw 5vw 0 5vw;

        .contact-text-wrapper {
            padding-bottom: .5em;


            h1 {
                width: 80%;
                font-size: 1.4em * 1.6;
            }

            p {
                width: 55%;
                max-width: $max-width * .38;
                font-size: 1.4em;
                margin: 2em 0 1em 0;
            }
        }


    }

    .std-button {
        font-size: .7em;
    }
}