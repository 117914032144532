@import "../styles/variables.scss";

.project-wrapper {
    width: inherit;
    margin: 0 0 1em 0;
    display: grid;
    overflow: visible;
    padding-bottom: 1em;
    grid-auto-rows: 1fr;

    :link,
    :visited {
        color: white;
        text-decoration: none;
    }


    :link:hover {
        color: white;
    }

    .project-card-link {
        overflow: visible;
        padding: 0;
        height: 100%;


        .project-card-wrapper {
            position: relative;
            transition: ease-out 200ms;
            background-color: $bg-color-dark;
            overflow: visible;
            height: 100%;


            .project-card {
                position: relative;
                display: block;
                margin: 0;
                padding: 0;
                border: none;
                overflow: hidden;

                .project-card-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                    aspect-ratio: 1 / 1;

                }

                @supports not (aspect-ratio: 1 / 1) {
                    .project-card-image::before {
                        float: left;
                        padding-top: 100%;
                        content: "";
                    }

                    .project-card-image::after {
                        display: block;
                        content: "";
                        clear: both;
                    }
                }


            }

            .project-card-text {
                padding: 0;
                overflow: hidden;

                h2 {
                    font-weight: 400;
                    letter-spacing: .2em;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                h3 {
                    font-weight: 400;
                    text-transform: uppercase;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    letter-spacing: .1em;
                }

                div {
                    padding: 0;
                    hyphens: auto;
                }

            }

            .text-link {
                position: absolute;
                overflow: hidden;
                text-transform: uppercase;
                font-weight: 500;
                font-size: .8em;
                letter-spacing: .1em;
                color: $second-accent-color;
                text-decoration: none;
                text-align: right;
                bottom: 0;
                right: 0;
                padding: .2em .75em .2em 1.5em;
                line-height: 2.5em;
                border-radius: 0 0 0 0;
            }


            .bi {
                display: inline;
                vertical-align: middle;
                font-size: 220%;
                padding: 0;
            }

        }

        .project-card-wrapper:hover .text-link {
            background-color: $second-accent-color;
            color: $bg-color-dark;
            transform: translateX(.75em);
        }

        .project-card-wrapper:hover .project-card-image {
            transform: scale(1.01);
        }

        .project-card-wrapper:active .text-link {
            background-color: $text-color;
            color: $bg-color-dark;
        }
    }

    .project-card-link:hover {
        filter: drop-shadow(.1em .1em .5em rgba($black-color, .5));
        transform: scale(1.01);
        

    }

    .project-card-wrapper:hover .project-card .project-card-image {
        filter: brightness(1.1);
    }

    .project-card-wrapper:hover {
        background-color: rgba($bg-color-dark, .5);
    }
}



@media only screen and (max-width: $break-sm) {
    .project-wrapper {
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 1fr;
        grid-row-gap: 2em;

        .project-card-link {

            .project-card-wrapper {

                .project-card-text {
                    margin: 1em 1em 1.5em 1.2em;

                    h2 {
                        font-size: 1.2em;
                        line-height: 1.15em;
                        width: calc(100% - 2.2em);
                        margin-bottom: .5em;
                        letter-spacing: .7vw;
                    }

                    h3 {
                        font-size: 1.2em * .6;
                        margin-bottom: 1.2em;
                        letter-spacing: .5vw;
                    }

                    div {
                        font-size: 1.2 * .8;
                        margin-bottom: 1.2em * 2;
                    }


                }

                .text-link {
                    //padding: 0 1em .7em 0;
                    font-size: 1.2em * .7;
                }
            }
        }
    }
}

@media only screen and (min-width: $break-sm) {
    .project-wrapper {
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 1em;
        grid-row-gap: 1em;
        margin: 1em;

        .project-card-link {

            .project-card-wrapper {

                .project-card-text {
                    margin: 1em 1em 1.5em 1em;

                    h2 {
                        font-size: 1.2em;
                        line-height: 1.1em;
                        width: calc(100% - 2em);
                        margin-bottom: .5em;
                        letter-spacing: .12vw;
                    }


                    h3 {
                        font-size: 1.2em * .6;
                        margin-bottom: 1.2em;
                    }

                    div {
                        font-size: 1.2em *.8;
                        margin-bottom: 1.2em * 2;
                    }


                }

                .text-link {
                    //padding: 0 1em .7em 0;
                    font-size: 1.2em * .7;
                }
            }
        }
    }
}

@media only screen and (min-width: $break-smp) {
    .project-wrapper {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 1em;
        grid-row-gap: 1em;
        margin: 1em;
    }
}

@media only screen and (min-width: $break-md) {
    .project-wrapper {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 1.5em;
        grid-row-gap: 1.5em;
        margin: 1.5em;

        .project-card-link {

            .project-card-wrapper {

                .project-card-text {
                    margin: 1em 1em 1.5em 1.2em;

                    h2 {
                        font-size: 1.3em;
                        line-height: 1.2em;
                        margin-bottom: .5em;
                        letter-spacing: .2em;
                    }

                    h3 {
                        font-size: 1.3em * .6;
                        margin-bottom: 1.3em;
                    }

                    div {
                        font-size: 1.3em * .8;
                        margin-bottom: 1.3em * 2;
                    }

                }

                .text-link {
                    //padding-right: 1em;
                    font-size: 1.3em * .7;
                }
            }
        }
    }
}

@media only screen and (min-width: $break-lg) {
    .project-wrapper {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-auto-rows: 1fr;
        grid-column-gap: 2em;
        grid-row-gap: 2em;
        margin: 2em;

        .project-card-link {

            .project-card-wrapper {

                .project-card-text {
                    margin: 1em 1em 1.5em 1.2em;

                    h2 {
                        font-size: 1.6em;
                        line-height: 1.2em;
                        margin-bottom: .5em;
                    }

                    h3 {
                        font-size: 1.6em * .6;
                        margin-bottom: 1.6em;
                    }

                    div {
                        font-size: 1.6em * .8;
                        margin-bottom: 1.6em * 2;
                    }

                }

                .text-link {
                    //padding-right: 1em;
                    font-size: 1.6em * .7;
                }
            }
        }
    }
}