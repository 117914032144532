@import "../styles/variables.scss";

.filler-ani-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;
    animation-name: initial-buffer;
    animation-iteration-count: 1;
    animation-duration: 5s;
}

#filler-ani {
    fill: rgba($text-color, .4);
    width: 200px;
    height: 200px;

    animation-name: filler-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

}

.inner-ani-wrapper2 {
    display: block;
    margin: auto;
    overflow: hidden; 

    animation-name: up-down-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

}

.inner-ani-wrapper {
    overflow: hidden;
    width: 100vw;
    padding-top: 19vh;
    animation-name: sideways-animation;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    transform: translateX(10%);

}

@keyframes initial-buffer{
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes filler-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(240deg);
    }
}

@keyframes up-down-animation {
    0% {
        transform: translateY(400%);
        opacity: 0;
    }

    50% {
        transform: translateY(-50%);
        opacity: 1;
    }

    95% {
        transform: translateY(440%);
        opacity: 0;
    }

    100% {
        transform: translateY(400%);
        opacity: 0;
    }
}

@keyframes sideways-animation {
    0% {
        transform: translateX(20vw);
    }

    9% {
        transform: translateX(15vw);
    }

    10% {
        transform: translateX(-5vw);
    }

    19% {
        transform: translateX(3vw);
    }

    20% {
        transform: translateX(40vw);
    }

    29% {
        transform: translateX(45vw);
    }

    30% {
        transform: translateX(30vw);
    }

    39% {
        transform: translateX(47vw);
    }

    40% {
        transform: translateX(-9vw);
    }

    49% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(60vw);
    }

    59% {
        transform: translateX(55vw);
    }

    60% {
        transform: translateX(35vw);
    }

    69% {
        transform: translateX(35vw);
    }

    70% {
        transform: translateX(60vw);
    }

    79% {
        transform: translateX(50vw);
    }

    80% {
        transform: translateX(12vw);
    }

    89% {
        transform: translateX(20vw);
    }

    90% {
        transform: translateX(55vw);
    }

    100% {
        transform: translateX(46vw);
    }
}

@media only screen and (max-width: $break-sm) {
    .filler-ani-wrapper {
        margin-top: 9vh;
    }

    #filler-ani {
        scale: .6;
        -webkit-transform: scale(.6);
        /* android, safari, chrome */
        -moz-transform: scale(.6);
        
        /* old firefox */
        -o-transform: scale(.6);
        
        /* old opera */
        -ms-transform: scale(.6);

        transform: scale(.6);
    }
}

@media only screen and (min-width: $break-sm) {
    .filler-ani-wrapper {
        margin-top: 13vh;
    }

    #filler-ani {
        scale: .4;
        -webkit-transform: scale(.4);
        /* android, safari, chrome */
        -moz-transform: scale(.4);
        
        /* old firefox */
        -o-transform: scale(.4);
        
        /* old opera */
        -ms-transform: scale(.4);

        transform: scale(.4);
    }
}

@media only screen and (min-width: $break-md) {
    .filler-ani-wrapper {
        margin-top: 9vh;
    }

    #filler-ani {
        scale: 1;
        -webkit-transform: scale(1);;
        /* android, safari, chrome */
        -moz-transform: scale(1);
        
        /* old firefox */
        -o-transform: scale(1);
        
        /* old opera */
        -ms-transform: scale(1);

        transform: scale(1);
    }
}

@media only screen and (min-width: $break-lg) {
    .filler-ani-wrapper {
        margin-top: 3vh;
    }

    #filler-ani {
        scale: .6;
        -webkit-transform: scale(.6);
        /* android, safari, chrome */
        -moz-transform: scale(.6);
        
        /* old firefox */
        -o-transform: scale(.6);
        
        /* old opera */
        -ms-transform: scale(.6);

        transform: scale(.6);
    }
}

@media only screen and (min-width: $max-width) {
    .filler-ani-wrapper {
        margin-top: 2vh;
    }

    #filler-ani {
        scale: .7;
        -webkit-transform: scale(.7);
        /* android, safari, chrome */
        -moz-transform: scale(.7);
        
        /* old firefox */
        -o-transform: scale(.7);
        
        /* old opera */
        -ms-transform: scale(.7);

        transform: scale(.7);
    }
}

@media only screen and (min-width: 2000px) {
    .filler-ani-wrapper {
        margin-top: 4vh;
    }

    #filler-ani {
        scale: .8;
        -webkit-transform: scale(.8);
        /* android, safari, chrome */
        -moz-transform: scale(.8);
        
        /* old firefox */
        -o-transform: scale(.8);
        
        /* old opera */
        -ms-transform: scale(.8);

        transform: scale(.8);
    }
}