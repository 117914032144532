@import "../styles/variables.scss";

.service-detail-overlay {
  position: fixed;
  display: inline-block;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: $bg-color;
  z-index: 1000;
  overflow-y: scroll;
  transition: ease 300ms;
  margin: 0;
  padding: 0;
  transition: none;
}

.service-head-wrapper {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  max-width: $max-width;
  padding: 0;

  .service-image-bg {
    position: fixed;
    max-width: $max-width;

  }

  .image-pusher {
    content: "";
    width: 100vw;
    margin: 0;
  }

  .service-head-img {
    position: relative;
    object-fit: cover;
    top: 0;
    width: 100vw;
    max-width: $max-width;
    z-index: 1200;
    filter: contrast(.9);
  }

  .header-logo-wrapper {
    position: fixed;
    z-index: 2000;
    background-color: $bg-color-dark;
    width: 100vw;
    max-width: $max-width;
    transition: none;
    display: flex;
    align-items: center;

    #hypersquare-logo {
      height: auto;
      display: inline-block;
      vertical-align: middle;
    }

    .hypersquare-logo {
      fill: $text-color;
    }

    .text-link {
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: .1em;
      color: $text-color;
      text-decoration: none;
      padding: .3em 1.5em .3em 1.15em;
      line-height: 2.2em;
      display: inline-block;
      vertical-align: middle;
      margin-left: auto;
      margin-right: 1em;
      white-space: nowrap;
      transition: ease 150ms;

    }

    .bi {
      display: inline;
      vertical-align: middle;
      font-size: 220%;
      padding: 0;
    }

    .text-link:hover {
      background-color: $text-color;
      color: $bg-color-dark;
    }

    .text-link:active {
      background-color: $text-color;
      color: $bg-color-dark;
    }

  }

}

.service-text-container {
  position: absolute;
  display: block;
  z-index: 1800;
  width: 100%;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  transition: none;
  overflow: hidden;


  .gallery-container {
    width: 100vw;
    max-width: $max-width;
    display: flex;
    flex-flow: row wrap;
    background-color: $bg-color;
    margin-left: auto;
    margin-right: auto;
    transition: none;

    .expand-button {
      position: absolute;
      overflow: hidden;
      right: 0;
      background-color: rgba($bg-color, .5);
      color: rgba($text-color, .7);
      filter: drop-shadow(.05em .05em .5em rgba($black-color, 0.5));
      border-radius: 1.5em;
      padding: 0;
      margin: 1em;
      border: .15em solid rgba($text-color, .7);
      width: 2.2em;
      height: 2.2em;
      transition: ease 150ms;
      pointer-events: auto;

      .bi {
        display: inline;
      }

      .bi-arrows-fullscreen {
        padding-left: .42em;
        line-height: 1.9em;
        font-size: 105%;
      }
    }

    .expand-button:hover {
      color: rgba($text-color, 1);
      border-color: rgba($text-color, 1);
      filter: drop-shadow(.05em .05em .5em rgba($black-color, 0.9));
      background-color: rgba($black-color, .25);
    }

    .expand-button-hidden {
      display: none;
    }

    .single-gallery-img-container {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: auto;

      .single-gallery-img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .gallery-img-container {
      position: relative;
      aspect-ratio: 1;
      overflow: hidden;
      order: 2;
      transition: none;
      cursor: pointer;

      .gallery-img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

    }

    .expanded-img {
      width: 100%;
      height: auto;
      aspect-ratio: 0;
      order: 1;
      cursor: auto;

      .gallery-img {
        object-fit: fill;
        width: 100%;
        height: 100%;
      }
    }

    .video-disabler {
      pointer-events: none;
    }

  }

}

.service-text-wrapper {
  background: linear-gradient(90deg,
      rgba($bg-color-dark, .92) 50%,
      rgba($bg-color-dark, .7));
  transition: none;

  h1 {
    line-height: 1.25em;

    text-decoration: none;
    font-weight: 400;
    font-size: 3em;

    margin: .25em 0 .2em 0;

  }
  .service-h1{
    margin: .25em 0 .7em 0;
  }

  .project-subheadline {
    line-height: 1.5em;
    font-weight: 400;
    margin: 0 0 2em 0;

    .project-date{
      font-size: 80%;
      opacity: .5;
    }
  }

  .intro-text {
    font-style: italic;
    overflow: hidden;


    p {
      margin-bottom: 0;
      line-height: 1.3em;
    }

    .intro-link-wrapper {
      margin-top: 3em;
      padding-bottom: 12vh;

      .text-link {
        text-transform: uppercase;
        font-weight: 500;
        font-style: normal;
        cursor: pointer;
        letter-spacing: .1em;
        color: $second-accent-color;
        text-decoration: none;
        padding: .3em 1.5em .3em 1.15em;
        line-height: 2.2em;

        margin-bottom: 1em;

        display: inline-block;
        align-items: flex-start;
        margin-right: 1em;
      }

      .bi {
        display: inline;
        vertical-align: middle;
        font-size: 220%;
        padding: 0;
      }

      .bi-card-image {
        display: inline;
        vertical-align: middle;
        font-size: 160%;
        padding: 0;
        padding-right: .5em;
        padding-left: .4em;
      }

      .text-link:hover {
        background-color: $second-accent-color;
        color: $bg-color-dark;
      }

      .text-link:active {
        background-color: $text-color;
        color: $bg-color-dark;
      }
    }
  }

  .main-text {


    h2 {
      line-height: 1.25em;
      font-weight: 400;

      margin: 0 0 .75em 0;
    }

    h3 {
      line-height: 1em;
      overflow: hidden;
      margin: 1em 0 .5em 0;
    }

    p {
      line-height: 1.5em;
      margin-bottom: 10vh;
    }

    #gallery-anchor {
      content: "";
    }
  }

}

.detail-footer {
  pointer-events: auto;

  .contact-area {
    position: relative;
    bottom: none;
  }

  .footer-links {
    margin-bottom: 0;
    padding-bottom: 2.4em;
  }
}

@media only screen and (max-width: $break-sm) {
  .service-head-wrapper {
    min-width: $min-width;

    .image-pusher {
      height: calc(8vw + 2em);
    }

    .service-head-img {
      height: calc(100vh - (8vw + 2em));

    }

    .header-logo-wrapper {
      #hypersquare-logo {
        width: 50vw;
        margin: 1em;
      }

      .text-link {
        font-size: .7em;
        margin-right: 1em;

        .desktop-button {
          display: none;
        }
      }
    }
  }

  .service-text-wrapper {
    width: 100vw;
    min-width: $min-width;
    padding: .8em 2em 0 1em;
    background: linear-gradient(90deg,
        rgba($bg-color-dark, .92) 60%,
        rgba($bg-color-dark, .85));

    h1 {
      font-size: 2.2em;
      padding-top: calc(10vh + 3em);
    }

    h2 {
      font-size: calc(1.2em * 1.6);
    }

    h3 {
      font-size: 1.2em;
    }

    p {
      font-size: 1.2em;
    }

    .project-subheadline {
      font-size: 1.2em * .8;
    }

    .intro-text {
      min-height: calc(90vh - 3em);

      p {
        font-size: calc(1.2em * 1.15);
      }

      .text-link {
        font-size: .8em;
      }

    }

  }

  .gallery-container {
    .gallery-img-container {
      width: calc(100% /2);
      height: calc(100% /2);
    }
  }

  .detail-footer {
    .footer-links {
      margin-bottom: 0;
      padding-bottom: 1em;
    }
  }

  #gallery-anchor {
    padding-top: calc(8vw + 2em);
  }

}

@media only screen and (min-width: $break-sm) {
  .service-head-wrapper {
    width: 100%;

    .image-pusher {
      height: calc(5vw + 2.2em);
    }

    .service-head-img {
      width: 100%;
      height: calc(100vh - (5vw + 2.2em));
    }

    .header-logo-wrapper {

      #hypersquare-logo {
        width: 35vw;
        margin: 1em;
        margin: 1em 4vw 1em 4vw;
      }

      .text-link {
        font-size: .75em;
        margin-right: 4vw;

        .desktop-button {
          display: contents;
        }

        .mobile-button {
          display: none;
        }
      }
    }
  }

  .service-text-wrapper {
    width: 100vw;
    padding: 1.5em 4vw 0 4vw;

    background: linear-gradient(90deg,
        rgba($bg-color-dark, .92) 50%,
        rgba($bg-color-dark, .75));

    h1 {
      font-size: 2.5em;
      padding-top: calc(10vh + 3em);
    }

    .project-subheadline {
      font-size: 1.3em * .8;
    }

    .intro-text,
    .main-text {
      width: 90%;
    }

    .intro-text {
      min-height: 70vh;

      p {
        font-size: calc(1.3em * 1.15);
      }
    }

    h2 {
      font-size: calc(1.3em * 1.6);
    }

    h3 {
      font-size: 1.3em;
    }

    p {
      font-size: 1.3em;
    }

    .text-link {
      font-size: .8em;
    }

    #gallery-anchor {
      padding-top: calc(5vw + 2.2em);
    }
  }

  .gallery-container {
    .gallery-img-container {
      width: calc(100% /3);
      height: calc(100% /3);
    }
  }
}

@media only screen and (min-width: $break-md) {
  .service-head-wrapper {
    width: 100%;

    .image-pusher {
      height: calc(5vw + 2em);
    }

    .service-head-img {
      width: 100%;
      height: calc(100vh - (5vw + 2em));

    }

    .header-logo-wrapper {
      #hypersquare-logo {
        width: 30vw;
        margin: 1em 4vw 1em 4vw;
      }

      .text-link {
        font-size: .9em;
      }
    }
  }

  .service-text-wrapper {
    width: 100vw;
    padding: 1.5em 4vw 0 4vw;

    background: linear-gradient(90deg,
        rgba($bg-color-dark, .92) 60%,
        rgba($bg-color-dark, .6));

    h1 {
      font-size: 3em;
    }

    .project-subheadline {
      font-size: 1.5em * .8;
    }

    .intro-text,
    .main-text {
      width: 75%;
    }

    .intro-text {


      p {
        font-size: calc(1.5em * 1.15);
      }
    }

    h2 {
      font-size: calc(1.5em * 1.6);
    }

    h3 {
      font-size: 1.5em;
    }

    p {
      font-size: 1.5em;
    }

    .text-link {
      font-size: .9em;
    }

    #gallery-anchor {
      padding-top: calc(5vw + 2em);
    }

  }

  .gallery-container {
    .gallery-img-container {
      width: calc(100% /3);
      height: calc(100% /3);
    }
  }

}

@media only screen and (min-width: $break-lg) {
  .service-head-wrapper {
    width: 100%;
    max-width: $max-width;

    .image-pusher {
      height: calc(4.5vw + 2em);
      max-height: 6.7em;
    }

    .service-head-img {
      width: 100%;
      height: calc(100vh - min(calc(5vw + 2em), 6.5em));
    }

    .header-logo-wrapper {
      max-width: $max-width;

      .text-link {
        font-size: 1em;
        margin-right: 2em;
      }

      #hypersquare-logo {
        width: 25vw;
        max-width: calc($min-width * 1.1);
        margin: 1.5em 2em 1.5em 2em;
      }
    }
  }

  .service-text-wrapper {
    width: 100vw;
    max-width: $max-width;
    padding: 1.5em 2em 0 2em;
    margin: 0;
    margin: auto;

    h1 {
      font-size: 3.5em;
      padding-top: calc(6vw + 3em + 5vh);
    }

    .project-subheadline {
      font-size: 1.5em * .8;
    }

    .intro-text,
    .main-text {
      width: 60%;
      max-width: $max-width * .55;
    }

    .intro-text {


      p {
        font-size: calc(1.5em * 1.15);
      }
    }

    h2 {
      font-size: calc(1.5em * 1.6); 
    }

    h3 {
      font-size: 1.5em;
    }

    p {
      font-size: 1.5em;
    }

    #gallery-anchor {
      padding-top: min(6.5em, calc(5vw + 2em));
    }

  }

  .gallery-container {
    .gallery-img-container {
      width: calc(100% /4);
      height: calc(100% /4);
    }
  }

}