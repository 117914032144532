@import "../styles/variables.scss";

.loader-wrapper {
    position: absolute;
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: $bg-color;

    display: flex;
    align-items: center;
}

#loading-logo {
    fill: rgba($text-color, .2);
    display: block;
    margin: 0 auto;
    height: 3em;
    width: 3em;

    animation-name: spin-animation;
    animation-duration: 1300ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

}

@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
        opacity: 1;
    }

    28% {
        transform: rotate(0deg);
        opacity: 1;
    }

    50% {
        transform: rotate(-30deg) scale(1);
        opacity: 1;
    }

    88% {
        transform: rotate(130deg) scale(1.05);
        opacity: .8;
    }

    100% {
        transform: rotate(120deg) scale(1);
        opacity: 1;
    }
}