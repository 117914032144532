@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300;400;700&display=swap");
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  overflow-x: hidden;
  transition: ease-out 200ms;
  text-rendering: optimizeLegibility;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #28272B;
}

.scroll-blocker {
  overflow: hidden;
}

@-moz-document url-prefix() {
  html,
  body {
    scroll-behavior: smooth;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Noto Sans Mono", monospace;
}

h1 {
  font-family: "Montserrat", sans-serif;
  margin: 0px;
  font-size: 2em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 500;
}

h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.125em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 500;
}

h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 500;
}

p {
  hyphenate-limit-chars: 10 3 4;
  -moz-hyphenate-limit-chars: 10 3 4;
  -webkit-hyphenate-limit-chars: 10 3 4;
  -ms-hyphenate-limit-chars: 10 3 4;
  -ms-hyphenate-limit-lines: 2;
  -webkit-hyphenate-limit-lines: 2;
}

:link,
:visited {
  color: rgb(175, 160, 255);
  text-decoration: none;
}

:link:hover {
  color: rgb(255, 238, 0);
}/*# sourceMappingURL=index.css.map */