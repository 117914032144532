.service-wrapper {
  overflow: visible;
  padding-bottom: 1em;
  display: flex;
  width: 100%;
}
.service-wrapper :link,
.service-wrapper :visited {
  color: white;
  text-decoration: none;
}
.service-wrapper :link:hover {
  color: white;
}
.service-wrapper .service-card-link {
  overflow: visible;
}
.service-wrapper .service-card-link .service-card-wrapper {
  background-color: #28272B;
  overflow: visible;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.service-wrapper .service-card-link .service-card-wrapper .service-card {
  display: block;
  position: relative;
  border: none;
  transition: ease-out 200ms;
  overflow: hidden;
  height: 80vh;
}
.service-wrapper .service-card-link .service-card-wrapper .service-card .service-card-head {
  display: block;
  position: absolute;
  z-index: 10;
  background-color: rgba(23, 23, 26, 0.85);
  height: 100%;
  overflow: hidden;
}
.service-wrapper .service-card-link .service-card-wrapper .service-card .service-card-head h2 {
  display: block;
  writing-mode: vertical-lr;
  font-weight: 400;
  letter-spacing: 0.2em;
  height: auto;
}
.service-wrapper .service-card-link .service-card-wrapper .service-card .service-card-image {
  z-index: 0;
  position: relative;
  height: 100%;
  width: auto;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
}
.service-wrapper .service-card-link .service-card-wrapper .service-card-text {
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex-grow: 1;
}
.service-wrapper .service-card-link .service-card-wrapper .service-card-text p {
  overflow: hidden;
  line-height: 1.4em;
}
.service-wrapper .service-card-link .service-card-wrapper .service-card-text .text-link {
  margin-top: auto;
  display: flex;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: rgb(255, 238, 0);
  text-decoration: none;
  padding: 0.2em 0.75em 0.2em 1.5em;
  line-height: 2.5em;
  border-radius: 0 0 0 0;
  width: -moz-max-content;
  width: max-content;
  align-self: flex-end;
}
.service-wrapper .service-card-link .service-card-wrapper .service-card-text .text-link .bi {
  display: inline;
  vertical-align: middle;
  font-size: 220%;
  padding: 0;
  margin: 0;
}
.service-wrapper .service-card-link .service-card-wrapper:hover .text-link {
  background-color: rgb(255, 238, 0);
  color: #17171A;
  transform: translateX(0.75em);
}
.service-wrapper .service-card-link .service-card-wrapper:active .text-link {
  background-color: #ffffff;
  color: #17171A;
}
.service-wrapper .service-card-link .service-card-wrapper:hover .service-card-image {
  transform: scale(1.02);
}
.service-wrapper .service-card-link .service-card-wrapper:hover .service-card .service-card-image {
  filter: brightness(1.1);
}
.service-wrapper .service-card-link .service-card-wrapper:hover {
  background-color: rgba(23, 23, 26, 0.5);
}
.service-wrapper .service-card-link .service-card-wrapper:hover .service-card .service-card-head {
  font-size: 105%;
}

@media only screen and (max-width: 475px) {
  .service-wrapper {
    flex-direction: column;
  }
  .service-wrapper .service-card-link {
    margin-bottom: 2em;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card {
    height: 70vh;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card .service-card-image {
    height: 100%;
    width: 100%;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card .service-card-head {
    padding: 1.5em;
    width: 5.12em;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card .service-card-head h2 {
    font-size: 1.92em;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card-text p {
    padding: 1.5em;
    font-size: 0.96;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card-text .text-link {
    font-size: 0.84em;
  }
}
@media only screen and (min-width: 475px) {
  .service-wrapper {
    flex-direction: column;
  }
  .service-wrapper .service-card-link {
    margin-bottom: 1em;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card {
    height: 45vh;
    min-height: 420px;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card .service-card-image {
    width: 100vw;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card .service-card-head {
    padding: 1.5em;
    width: 5.42em;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card .service-card-head h2 {
    font-size: 1.92em;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card-text p {
    padding: 1.1em;
    font-size: 0.96em;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card-text .text-link {
    font-size: 0.84em;
  }
}
@media only screen and (min-width: 900px) {
  .service-wrapper {
    flex-direction: row;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card-head {
    padding: 1.5em;
    width: 5.08em;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card-head h2 {
    font-size: 2.08em;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card {
    height: 70vh;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card .service-card-image {
    width: 100%;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card-text p {
    padding: 1.5em;
    font-size: 1.04em;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card-text .text-link {
    font-size: 0.91em;
  }
}
@media only screen and (min-width: 1200px) {
  .service-wrapper .service-card-link .service-card-wrapper .service-card-head {
    padding: 2em;
    width: 5.56em;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card-head h2 {
    font-size: 2.56em;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card-text p {
    padding: 1.6em;
    font-size: 1.28em;
  }
  .service-wrapper .service-card-link .service-card-wrapper .service-card-text .text-link {
    font-size: 1.12em;
  }
}/*# sourceMappingURL=ServiceCard.css.map */