@import "../styles/variables.scss";

.service-section {
    margin-top: 0;
}

.service-head {
    min-width: calc($min-width - 4em);

    br {
        display: inline-block;
    }

    video {
        pointer-events: none;
        mix-blend-mode: normal;
        border-radius: 50%;
        -webkit-mask-image: radial-gradient(black 45%, rgba(0, 0, 0, 0));
        mask-image: radial-gradient(black 45%, rgba(0, 0, 0, 0));
    }

    //@supports (-webkit-hyphens:none) {
    //     video {
    //         mix-blend-mode: lighten;
    //     }}



    .intro-subtitle {
        color: $second-accent-color;
        font-weight: 500;
        letter-spacing: .1em;
    }

    h1 {
        font-weight: 400;
        letter-spacing: .15em;
    }

    p {
        font-style: italic;
        line-height: 1.4em;
    }
}



@media only screen and (max-width: $break-sm) {
    .service-head {
        padding: 13vh 2em 13vh 2em;


        video {
            width: 80%;
            display: block;
            margin: auto;
            margin-bottom: 2em;
        }

        .intro-subtitle {
            font-size: 1.4em * .6;
        }

        h1 {
            font-size: 1.4em * 1.6;
            margin-top: 1em * .2;
            margin-bottom: 1em;
            max-width: 90%;
        }

        p {
            font-size: 1.4em;
            hyphens: auto;
            -webkit-hyphens: auto;
            -ms-hyphens: auto;
        }
    }

}

@media only screen and (min-width: $break-sm) {
    .service-head {
        padding: 20vh 0 13vh 0;
        margin: 0 auto;


        video {
            width: 65%;
            max-width: $min-width;
            display: block;
            margin: auto;
            margin-bottom: 2em;
        }

        .intro-subtitle {
            font-size: 1.4em * .6;
        }

        h1 {
            font-size: 1.4em * 1.6;
            margin-top: 1em * .2;
            margin-bottom: 1em;
        }

        p {
            font-size: 1.4em;
        }
    }

}

@media only screen and (min-width: $break-md) {
    .service-head {
        padding: 25vh 0 18vh 0;
        margin: 0 auto;


        video {
            display: inline-block;
            margin: auto;
            vertical-align: middle;

            width: 40%;
        }

        .text-wrapper {
            display: inline-block;
            vertical-align: middle;

            padding-left: 1em;
            width: 60%;
        }


        .intro-subtitle {
            font-size: 1.5em * .6;
        }

        h1 {
            font-size: 1.5em * 1.4;
            margin-top: 1em * .2;
            margin-bottom: 1em;
        }

        p {
            font-size: 1.5em;
        }
    }

}

@media only screen and (min-width: $break-lg) {
    .service-head {
        padding: 28vh 0 18vh 0;
        margin: 0 auto;


        video {
            width: 30%;
            max-width: 30%;
        }

        .text-wrapper {
            padding-left: 1.5em;
            width: 70%;
        }

        .intro-subtitle {
            font-size: 1.8em * .6;
        }

        h1 {
            font-size: 1.8em * 1.6;
            margin-top: 1.2em * .2;
            margin-bottom: 1em;
        }

        p {
            font-size: 1.8em;
            max-width: 95%;
        }
    }

}