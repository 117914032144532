@import "../styles/variables.scss";

.service-wrapper {
    overflow: visible;
    padding-bottom: 1em;
    display: flex;
    width: 100%;


    :link,
    :visited {
        color: white;
        text-decoration: none;
    }


    :link:hover {
        color: white;
    }

    .service-card-link {
        overflow: visible;

        .service-card-wrapper {
            background-color: $bg-color;
            overflow: visible;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;


            .service-card {
                display: block;
                position: relative;
                border: none;
                transition: ease-out 200ms;
                overflow: hidden;
                height: 80vh;

                .service-card-head {
                    display: block;
                    position: absolute;
                    z-index: 10;
                    background-color: rgba($bg-color-dark, 0.85);
                    height: 100%;
                    overflow: hidden;


                    h2 {
                        display: block;
                        writing-mode: vertical-lr;
                        font-weight: 400;
                        letter-spacing: .2em;
                        height: auto;
                    }

                }

                .service-card-image {
                    z-index: 0;
                    position: relative;
                    height: 100%;
                    width: auto;
                    display: block;
                    object-fit: cover;
                }
            }


            .service-card-text {
                overflow: visible;
                display: flex;
                flex-direction: column;
                align-self: stretch;
                flex-grow: 1;


                p {
                    overflow: hidden;
                    line-height: 1.4em;
                }

                .text-link {
                    margin-top: auto;
                    display: flex;
                    text-transform: uppercase;
                    font-weight: 500;
                    letter-spacing: .1em;
                    color: $second-accent-color;
                    text-decoration: none;
                    padding: .2em .75em .2em 1.5em;
                    line-height: 2.5em;
                    border-radius: 0 0 0 0;
                    width: max-content;
                    align-self: flex-end;

                    .bi {
                        display: inline;
                        vertical-align: middle;
                        font-size: 220%;
                        padding: 0;
                        margin: 0;
                    }
                }
            }





        }

        .service-card-wrapper:hover .text-link {
            background-color: $second-accent-color;
            color: $bg-color-dark;
            transform: translateX(.75em);
        }

        .service-card-wrapper:active .text-link {
            background-color: $text-color;
            color: $bg-color-dark;            
        }

        .service-card-wrapper:hover .service-card-image {
            transform: scale(1.02);
        }

        .service-card-wrapper:hover .service-card .service-card-image {
            filter: brightness(1.1);
        }

        .service-card-wrapper:hover {
            background-color: rgba($bg-color-dark, .5);
        }

        .service-card-wrapper:hover .service-card .service-card-head{
            font-size: 105%;
        }


    }

}






@media only screen and (max-width: $break-sm) {
    .service-wrapper {
        flex-direction: column;
        //width: 100%;

        .service-card-link {
            margin-bottom: 2em;

            .service-card-wrapper {
                //width: 100vw;

                .service-card {
                    height: 70vh;

                    .service-card-image {
                        height: 100%;
                        width: 100%;
                    }

                    .service-card-head {
                        padding: 1.5em;
                        width: 5.12em;

                        h2 {
                            font-size: 1.2em * 1.6;
                        }

                    }
                }

                .service-card-text {

                    p {
                        padding: 1.5em;
                        font-size: 1.2 * .8;
                    }

                    .text-link {
                        font-size: 1.2em * .7;
                    }

                }


            }
        }
    }
}


@media only screen and (min-width: $break-sm) {
    .service-wrapper {
        flex-direction: column;

        .service-card-link {
            margin-bottom: 1em;

            .service-card-wrapper {

                .service-card {
                    height: 45vh;
                    min-height: 420px;

                    .service-card-image {
                        width: 100vw;
                    }

                    .service-card-head {
                        padding: 1.5em;
                        width: 5.42em;

                        h2 {
                            font-size: 1.2em * 1.6;
                        }

                    }
                }

                .service-card-text {

                    p {
                        padding: 1.1em;
                        font-size: 1.2em *.8;
                    }

                    .text-link {
                        font-size: 1.2em * .7;
                    }
                }


            }
        }
    }
}

@media only screen and (min-width: $break-md) {
    .service-wrapper {
        flex-direction: row;

        .service-card-link {

            .service-card-wrapper {
                .service-card-head {
                    padding: 1.5em;
                    width: 5.08em;

                    h2 {
                        font-size: 1.3em * 1.6;
                    }

                }

                .service-card {
                    height: 70vh;

                    .service-card-image {
                        width: 100%;
                    }

                }

                .service-card-text {

                    p {
                        padding: 1.5em;
                        font-size: 1.3em * .8;
                    }

                    .text-link {
                        font-size: 1.3em * .7;
                    }
                }


            }
        }
    }
}

@media only screen and (min-width: $break-lg) {
    .service-wrapper {

        .service-card-link {

            .service-card-wrapper {

                .service-card-head {
                    padding: 2em;
                    width: 5.56em;

                    h2 {
                        font-size: 1.6em *1.6;
                    }
                }

                .service-card-text {
                    p {
                        padding: 1.6em;
                        font-size: 1.6em * .8;
                    }

                    .text-link {
                        font-size: 1.6em * .7;
                    }
                }


            }
        }
    }
}