.navbar {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  overflow: hidden;
  -webkit-backdrop-filter: blur(0.25em);
          backdrop-filter: blur(0.25em);
  padding: 0;
}

.nav-link,
.nav-link:visited {
  display: inline-block;
  overflow: hidden;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.8);
  transition: ease 150ms;
  cursor: pointer;
  box-sizing: border-box;
  border: 0.1em solid transparent;
}

.active {
  pointer-events: none;
  color: #ffffff;
  border: 0.1em solid rgba(175, 160, 255, 0.75);
  background-color: rgba(175, 160, 255, 0.1);
}

.nav-link:hover {
  color: rgb(175, 160, 255);
}

.nav-link:active {
  color: #ffffff;
}

@media only screen and (max-width: 475px) {
  .navbar {
    width: calc(100vw - 2.5em);
    min-width: calc(360px - 5em);
    height: 4em;
    border-radius: 2em;
    bottom: 1em;
    display: flex;
    text-align: center;
    justify-content: left;
    margin: 0 1em 0 1em;
    padding: 0 0.2em 0 0.2em;
  }
  .nav-link {
    font-size: 0.5em;
    width: calc(69.2307692308px - 12em + 30vw);
    min-width: 64.2857142857px;
    margin-top: 0.4em;
    height: 7.2em;
    border-radius: 4em;
    line-height: 3em;
    border-width: 0.2em;
  }
  .active {
    background-color: transparent;
    color: rgb(175, 160, 255);
    border-color: transparent;
  }
  #first-nav {
    margin-left: 1em;
  }
  .bi {
    font-size: 300%;
    position: relative;
    display: block;
    margin-top: 0.3em;
  }
  .nav-text {
    position: relative;
    display: block;
    text-align: center;
  }
}
@media only screen and (min-width: 475px) {
  .navbar {
    width: auto;
    height: 3em;
    border-radius: 4em;
    top: 1em;
    right: 1em;
    line-height: 3em;
    padding: 0 0.25em 0 0.25em;
  }
  .nav-link {
    padding: 0 1.2em 0 1.2em;
    font-size: 0.9em;
    margin-top: 0.22em;
    height: 2.9em;
    border-radius: 2em;
    line-height: 2.7em;
  }
  .bi {
    font-size: 150%;
    padding-right: 0.3em;
  }
  .nav-text {
    vertical-align: top;
  }
}
@media only screen and (min-width: 900px) {
  .navbar {
    width: auto;
    height: 3.6em;
    border-radius: 1.8em;
    top: 1.5em;
    right: 1.5em;
    line-height: 3.6em;
    padding: 0 0.28em 0 0.28em;
  }
  .nav-link {
    padding: 0 1.6em 0 1.6em;
    font-size: 1em;
    margin-top: 0.3em;
    height: 3em;
    border-radius: 2em;
    line-height: 2.9em;
  }
  .bi {
    font-size: 150%;
    padding-right: 0.5em;
  }
}
@media only screen and (min-width: 1200px) {
  .navbar {
    width: auto;
    height: 4em;
    border-radius: 2em;
    top: 1.5em;
    right: 1.5em;
    line-height: 4em;
    padding: 0 0.28em 0 0.28em;
  }
  .nav-link {
    padding: 0 1.6em 0 1.6em;
    font-size: 1.15em;
    margin-top: 0.24em;
    height: 3em;
    border-radius: 2em;
    line-height: 2.9em;
  }
  .bi {
    font-size: 150%;
    padding-right: 0.5em;
  }
}/*# sourceMappingURL=Navbar.css.map */