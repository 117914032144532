@import "../styles/variables.scss";

.logo-section {

    h2 {
        font-weight: 400;
        margin-bottom: 1em;
    }
}

.logo-section-wrapper {
    display: grid;
    grid-gap: 1em;

    grid-template-columns: repeat(5, 1fr);

}

.partner-logo {
    cursor: pointer;
    position: relative;
    margin: 0;
    padding: 0;
    overflow: hidden;

    /* Fancy border code */
    --b: .08em;
    /* Thickness of the border */
    --w: 10%;
    /* Width of border */
    
}

.partner-logo::before {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba($text-color, .6);
    --_g: #0000 90deg, #000 0;
    --_p: var(--w) var(--w) no-repeat;
    --mask:
        conic-gradient(from 90deg at top var(--b) left var(--b), var(--_g)) 0 0 / var(--_p),
        conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 / var(--_p),
        conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% / var(--_p),
        conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g)) 100% 100% / var(--_p);
    -webkit-mask: var(--mask);
    mask: var(--mask);

    transition: ease 200ms;
}


.partner-logo:hover {
    background: rgba($text-color, .05);
    --w: 50%;
}

.partner-logo:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.partner-logo-img {
    position: absolute;
    width: 70%;
    height: 70%;
    margin: 15% 0 0 15%;
    justify-self: left;
}

@media only screen and (max-width: $break-sm) {
    .logo-section {
        padding-top: 8em;

        h2 {
            width: calc(100vw - 2em);
            font-size: 1.2em;
            margin: 0 1em 1em 1em;
        }
    }

    .logo-section-wrapper {
        padding: 0 1em 1em 1em;
        grid-gap: 1em;
        grid-template-columns: repeat(3, 1fr);

    }

}

@media only screen and (min-width: $break-sm) {
    .logo-section {
        padding: 12em 1.5em 2em 1.5em;

        h2 {
            font-size: 1.3em;
        }
    }

    .logo-section-wrapper {

        grid-template-columns: repeat(5, 1fr);

    }

}

@media only screen and (min-width: $break-md) {
    .logo-section {
        padding: 14em 1.5em 1em 1.5em;

        h2 {
            font-size: 1.3em;
        }
    }

    .logo-section-wrapper {

        grid-template-columns: repeat(6, 1fr);

    }

}

@media only screen and (min-width: $break-lg) {
    .logo-section {
        padding: 16em 2em 1em 2em;

        h2 {
            font-size: 1.6em;
        }
    }

    .logo-section-wrapper {

        grid-template-columns: repeat(12, 1fr);

    }

}