@import "../styles/variables.scss";

.navbar {
    position: fixed;
    background-color: rgba($black-color, .7);
    z-index: 100;
    overflow: hidden;
    //filter: drop-shadow(0 .05em .5em rgba($black-color, 0.5));
    backdrop-filter: blur(.25em);
    padding: 0;    

}

.nav-link,
.nav-link:visited {
    display: inline-block;
    overflow: hidden;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .05em;
    color: rgba($text-color, .8);
    transition: ease 150ms;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: .1em solid transparent;
    
}

// Handles the active state when the page is scrolled to the respective position (with scroll-spy)
.active {
    pointer-events: none;
    color: $text-color;
    border: .1em solid rgba($first-accent-color-light, .75);
    background-color: rgba($first-accent-color-light, .1);
}


.nav-link:hover {
    color: $first-accent-color-light;
    //filter: drop-shadow(0 0 1em $first-accent-color-light) brightness(125%);
}

.nav-link:active {
    color: $text-color;
}



@media only screen and (max-width: $break-sm) {
    .navbar {
        width: calc(100vw - 2.5em);
        min-width: calc($min-width - 5em);
        height: 4em;
        border-radius: 2em;
        bottom: 1em;
        display: flex;
        text-align: center;
        justify-content: left;
        margin: 0 1em 0 1em;
        padding: 0 .2em 0 .2em;
    }

    .nav-link {
        //padding: 0 0.3em 0 0.3em;
        font-size: .5em;
        width: calc($min-width / 5.2 - 12em + 30vw);
        min-width: calc($min-width / 5.6);
        margin-top: .4em;
        height: 7.2em;
        border-radius: 4em;
        line-height: 3em;
        border-width: .2em;
    }
    
    .active {
        background-color: transparent;
        color: $first-accent-color-light;
        border-color: transparent;
    }

    #first-nav{
        margin-left: 1em;
    }
    

    .bi {
        font-size: 300%;
        position: relative;
        display: block;
        margin-top: .3em;
    }

    .nav-text {
        position: relative;
        display: block;
        text-align: center;

    }
}

@media only screen and (min-width: $break-sm) {
    .navbar {
        width: auto;
        height: 3em;
        border-radius: 4em;
        top: 1em;
        right: 1em;
        line-height: 3em;
        padding: 0 .25em 0 .25em;
    }

    .nav-link {
        padding: 0 1.2em 0 1.2em;
        font-size: .9em;
        margin-top: .22em;
        height: 2.9em;
        border-radius: 2em;
        line-height: 2.7em;
    }

    .bi {
        font-size: 150%;
        padding-right: .3em;
    }

    .nav-text {
        vertical-align: top;
    }
}

@media only screen and (min-width: $break-md) {
    .navbar {
        width: auto;
        height: 3.6em;
        border-radius: 1.8em;
        top: 1.5em;
        right: 1.5em;
        line-height: 3.6em;
        padding: 0 .28em 0 .28em;
    }

    .nav-link {
        padding: 0 1.6em 0 1.6em;
        font-size: 1em;
        margin-top: .3em;
        height: 3em;
        border-radius: 2em;
        line-height: 2.9em;
    }

    .bi {
        font-size: 150%;
        padding-right: .5em;
    }
}

@media only screen and (min-width: $break-lg) {
    .navbar {
        width: auto;
        height: 4em;
        border-radius: 2em;
        top: 1.5em;
        right: 1.5em;
        line-height: 4em;
        padding: 0 .28em 0 .28em;
    }

    .nav-link {
        padding: 0 1.6em 0 1.6em;
        font-size: 1.15em;
        margin-top: .24em;
        height: 3em;
        border-radius: 2em;
        line-height: 2.9em;
    }
    
    .bi {
        font-size: 150%;
        padding-right: .5em;
    }
}