$black-color: #000000;
$bg-color: #28272B;
$bg-color-dark: #17171A;
$text-color: #ffffff;
$text-color-b: rgba(var($text-color), 0.8);
$first-accent-color: rgb(124, 0, 207);
$second-accent-color: rgb(255, 238, 0);
$first-accent-color-light: rgb(175, 160, 255);
$second-accent-color-light: rgb(255, 255, 122);


$min-width: 360px;
$break-sm: 475px;
$break-smp: 650px;
$break-md: 900px;
$break-lg: 1200px;
$break-xl: 1500px;
$max-width: 1800px;