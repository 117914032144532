.legal-notice-detail {
  background: rgba(23, 23, 26, 0.8);
  padding-bottom: 5em;
  min-height: 100vh;
}
.legal-notice-detail h2 {
  text-transform: none;
  padding-top: 1em;
  font-weight: 600;
}
.legal-notice-detail h3 {
  text-transform: none;
  padding-top: 2em;
  font-weight: 600;
}
.legal-notice-detail p {
  padding-top: 0.5em;
}

@media only screen and (max-width: 475px) {
  .legal-notice-detail h1 {
    padding-top: calc(5vw + 2em);
  }
}
@media only screen and (min-width: 475px) {
  .legal-notice-detail h1 {
    padding-top: calc(5vw + 2em);
  }
}
@media only screen and (min-width: 900px) {
  .legal-notice-detail h1 {
    padding-top: calc(5vw + 2em);
  }
}
@media only screen and (min-width: 1200px) {
  .legal-notice-detail h1 {
    padding-top: calc(5vw + 2em);
  }
}/*# sourceMappingURL=LegalNotice.css.map */