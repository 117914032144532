.service-detail-overlay {
  position: fixed;
  display: inline-block;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: #28272B;
  z-index: 1000;
  overflow-y: scroll;
  transition: ease 300ms;
  margin: 0;
  padding: 0;
  transition: none;
}

.service-head-wrapper {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  max-width: 1800px;
  padding: 0;
}
.service-head-wrapper .service-image-bg {
  position: fixed;
  max-width: 1800px;
}
.service-head-wrapper .image-pusher {
  content: "";
  width: 100vw;
  margin: 0;
}
.service-head-wrapper .service-head-img {
  position: relative;
  -o-object-fit: cover;
     object-fit: cover;
  top: 0;
  width: 100vw;
  max-width: 1800px;
  z-index: 1200;
  filter: contrast(0.9);
}
.service-head-wrapper .header-logo-wrapper {
  position: fixed;
  z-index: 2000;
  background-color: #17171A;
  width: 100vw;
  max-width: 1800px;
  transition: none;
  display: flex;
  align-items: center;
}
.service-head-wrapper .header-logo-wrapper #hypersquare-logo {
  height: auto;
  display: inline-block;
  vertical-align: middle;
}
.service-head-wrapper .header-logo-wrapper .hypersquare-logo {
  fill: #ffffff;
}
.service-head-wrapper .header-logo-wrapper .text-link {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #ffffff;
  text-decoration: none;
  padding: 0.3em 1.5em 0.3em 1.15em;
  line-height: 2.2em;
  display: inline-block;
  vertical-align: middle;
  margin-left: auto;
  margin-right: 1em;
  white-space: nowrap;
  transition: ease 150ms;
}
.service-head-wrapper .header-logo-wrapper .bi {
  display: inline;
  vertical-align: middle;
  font-size: 220%;
  padding: 0;
}
.service-head-wrapper .header-logo-wrapper .text-link:hover {
  background-color: #ffffff;
  color: #17171A;
}
.service-head-wrapper .header-logo-wrapper .text-link:active {
  background-color: #ffffff;
  color: #17171A;
}

.service-text-container {
  position: absolute;
  display: block;
  z-index: 1800;
  width: 100%;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  transition: none;
  overflow: hidden;
}
.service-text-container .gallery-container {
  width: 100vw;
  max-width: 1800px;
  display: flex;
  flex-flow: row wrap;
  background-color: #28272B;
  margin-left: auto;
  margin-right: auto;
  transition: none;
}
.service-text-container .gallery-container .expand-button {
  position: absolute;
  overflow: hidden;
  right: 0;
  background-color: rgba(40, 39, 43, 0.5);
  color: rgba(255, 255, 255, 0.7);
  filter: drop-shadow(0.05em 0.05em 0.5em rgba(0, 0, 0, 0.5));
  border-radius: 1.5em;
  padding: 0;
  margin: 1em;
  border: 0.15em solid rgba(255, 255, 255, 0.7);
  width: 2.2em;
  height: 2.2em;
  transition: ease 150ms;
  pointer-events: auto;
}
.service-text-container .gallery-container .expand-button .bi {
  display: inline;
}
.service-text-container .gallery-container .expand-button .bi-arrows-fullscreen {
  padding-left: 0.42em;
  line-height: 1.9em;
  font-size: 105%;
}
.service-text-container .gallery-container .expand-button:hover {
  color: white;
  border-color: white;
  filter: drop-shadow(0.05em 0.05em 0.5em rgba(0, 0, 0, 0.9));
  background-color: rgba(0, 0, 0, 0.25);
}
.service-text-container .gallery-container .expand-button-hidden {
  display: none;
}
.service-text-container .gallery-container .single-gallery-img-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
}
.service-text-container .gallery-container .single-gallery-img-container .single-gallery-img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.service-text-container .gallery-container .gallery-img-container {
  position: relative;
  aspect-ratio: 1;
  overflow: hidden;
  order: 2;
  transition: none;
  cursor: pointer;
}
.service-text-container .gallery-container .gallery-img-container .gallery-img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.service-text-container .gallery-container .expanded-img {
  width: 100%;
  height: auto;
  aspect-ratio: 0;
  order: 1;
  cursor: auto;
}
.service-text-container .gallery-container .expanded-img .gallery-img {
  -o-object-fit: fill;
     object-fit: fill;
  width: 100%;
  height: 100%;
}
.service-text-container .gallery-container .video-disabler {
  pointer-events: none;
}

.service-text-wrapper {
  background: linear-gradient(90deg, rgba(23, 23, 26, 0.92) 50%, rgba(23, 23, 26, 0.7));
  transition: none;
}
.service-text-wrapper h1 {
  line-height: 1.25em;
  text-decoration: none;
  font-weight: 400;
  font-size: 3em;
  margin: 0.25em 0 0.2em 0;
}
.service-text-wrapper .service-h1 {
  margin: 0.25em 0 0.7em 0;
}
.service-text-wrapper .project-subheadline {
  line-height: 1.5em;
  font-weight: 400;
  margin: 0 0 2em 0;
}
.service-text-wrapper .project-subheadline .project-date {
  font-size: 80%;
  opacity: 0.5;
}
.service-text-wrapper .intro-text {
  font-style: italic;
  overflow: hidden;
}
.service-text-wrapper .intro-text p {
  margin-bottom: 0;
  line-height: 1.3em;
}
.service-text-wrapper .intro-text .intro-link-wrapper {
  margin-top: 3em;
  padding-bottom: 12vh;
}
.service-text-wrapper .intro-text .intro-link-wrapper .text-link {
  text-transform: uppercase;
  font-weight: 500;
  font-style: normal;
  cursor: pointer;
  letter-spacing: 0.1em;
  color: rgb(255, 238, 0);
  text-decoration: none;
  padding: 0.3em 1.5em 0.3em 1.15em;
  line-height: 2.2em;
  margin-bottom: 1em;
  display: inline-block;
  align-items: flex-start;
  margin-right: 1em;
}
.service-text-wrapper .intro-text .intro-link-wrapper .bi {
  display: inline;
  vertical-align: middle;
  font-size: 220%;
  padding: 0;
}
.service-text-wrapper .intro-text .intro-link-wrapper .bi-card-image {
  display: inline;
  vertical-align: middle;
  font-size: 160%;
  padding: 0;
  padding-right: 0.5em;
  padding-left: 0.4em;
}
.service-text-wrapper .intro-text .intro-link-wrapper .text-link:hover {
  background-color: rgb(255, 238, 0);
  color: #17171A;
}
.service-text-wrapper .intro-text .intro-link-wrapper .text-link:active {
  background-color: #ffffff;
  color: #17171A;
}
.service-text-wrapper .main-text h2 {
  line-height: 1.25em;
  font-weight: 400;
  margin: 0 0 0.75em 0;
}
.service-text-wrapper .main-text h3 {
  line-height: 1em;
  overflow: hidden;
  margin: 1em 0 0.5em 0;
}
.service-text-wrapper .main-text p {
  line-height: 1.5em;
  margin-bottom: 10vh;
}
.service-text-wrapper .main-text #gallery-anchor {
  content: "";
}

.detail-footer {
  pointer-events: auto;
}
.detail-footer .contact-area {
  position: relative;
  bottom: none;
}
.detail-footer .footer-links {
  margin-bottom: 0;
  padding-bottom: 2.4em;
}

@media only screen and (max-width: 475px) {
  .service-head-wrapper {
    min-width: 360px;
  }
  .service-head-wrapper .image-pusher {
    height: calc(8vw + 2em);
  }
  .service-head-wrapper .service-head-img {
    height: calc(100vh - (8vw + 2em));
  }
  .service-head-wrapper .header-logo-wrapper #hypersquare-logo {
    width: 50vw;
    margin: 1em;
  }
  .service-head-wrapper .header-logo-wrapper .text-link {
    font-size: 0.7em;
    margin-right: 1em;
  }
  .service-head-wrapper .header-logo-wrapper .text-link .desktop-button {
    display: none;
  }
  .service-text-wrapper {
    width: 100vw;
    min-width: 360px;
    padding: 0.8em 2em 0 1em;
    background: linear-gradient(90deg, rgba(23, 23, 26, 0.92) 60%, rgba(23, 23, 26, 0.85));
  }
  .service-text-wrapper h1 {
    font-size: 2.2em;
    padding-top: calc(10vh + 3em);
  }
  .service-text-wrapper h2 {
    font-size: 1.92em;
  }
  .service-text-wrapper h3 {
    font-size: 1.2em;
  }
  .service-text-wrapper p {
    font-size: 1.2em;
  }
  .service-text-wrapper .project-subheadline {
    font-size: 0.96em;
  }
  .service-text-wrapper .intro-text {
    min-height: calc(90vh - 3em);
  }
  .service-text-wrapper .intro-text p {
    font-size: 1.38em;
  }
  .service-text-wrapper .intro-text .text-link {
    font-size: 0.8em;
  }
  .gallery-container .gallery-img-container {
    width: 50%;
    height: 50%;
  }
  .detail-footer .footer-links {
    margin-bottom: 0;
    padding-bottom: 1em;
  }
  #gallery-anchor {
    padding-top: calc(8vw + 2em);
  }
}
@media only screen and (min-width: 475px) {
  .service-head-wrapper {
    width: 100%;
  }
  .service-head-wrapper .image-pusher {
    height: calc(5vw + 2.2em);
  }
  .service-head-wrapper .service-head-img {
    width: 100%;
    height: calc(100vh - (5vw + 2.2em));
  }
  .service-head-wrapper .header-logo-wrapper #hypersquare-logo {
    width: 35vw;
    margin: 1em;
    margin: 1em 4vw 1em 4vw;
  }
  .service-head-wrapper .header-logo-wrapper .text-link {
    font-size: 0.75em;
    margin-right: 4vw;
  }
  .service-head-wrapper .header-logo-wrapper .text-link .desktop-button {
    display: contents;
  }
  .service-head-wrapper .header-logo-wrapper .text-link .mobile-button {
    display: none;
  }
  .service-text-wrapper {
    width: 100vw;
    padding: 1.5em 4vw 0 4vw;
    background: linear-gradient(90deg, rgba(23, 23, 26, 0.92) 50%, rgba(23, 23, 26, 0.75));
  }
  .service-text-wrapper h1 {
    font-size: 2.5em;
    padding-top: calc(10vh + 3em);
  }
  .service-text-wrapper .project-subheadline {
    font-size: 1.04em;
  }
  .service-text-wrapper .intro-text,
  .service-text-wrapper .main-text {
    width: 90%;
  }
  .service-text-wrapper .intro-text {
    min-height: 70vh;
  }
  .service-text-wrapper .intro-text p {
    font-size: 1.495em;
  }
  .service-text-wrapper h2 {
    font-size: 2.08em;
  }
  .service-text-wrapper h3 {
    font-size: 1.3em;
  }
  .service-text-wrapper p {
    font-size: 1.3em;
  }
  .service-text-wrapper .text-link {
    font-size: 0.8em;
  }
  .service-text-wrapper #gallery-anchor {
    padding-top: calc(5vw + 2.2em);
  }
  .gallery-container .gallery-img-container {
    width: 33.3333333333%;
    height: 33.3333333333%;
  }
}
@media only screen and (min-width: 900px) {
  .service-head-wrapper {
    width: 100%;
  }
  .service-head-wrapper .image-pusher {
    height: calc(5vw + 2em);
  }
  .service-head-wrapper .service-head-img {
    width: 100%;
    height: calc(100vh - (5vw + 2em));
  }
  .service-head-wrapper .header-logo-wrapper #hypersquare-logo {
    width: 30vw;
    margin: 1em 4vw 1em 4vw;
  }
  .service-head-wrapper .header-logo-wrapper .text-link {
    font-size: 0.9em;
  }
  .service-text-wrapper {
    width: 100vw;
    padding: 1.5em 4vw 0 4vw;
    background: linear-gradient(90deg, rgba(23, 23, 26, 0.92) 60%, rgba(23, 23, 26, 0.6));
  }
  .service-text-wrapper h1 {
    font-size: 3em;
  }
  .service-text-wrapper .project-subheadline {
    font-size: 1.2em;
  }
  .service-text-wrapper .intro-text,
  .service-text-wrapper .main-text {
    width: 75%;
  }
  .service-text-wrapper .intro-text p {
    font-size: 1.725em;
  }
  .service-text-wrapper h2 {
    font-size: 2.4em;
  }
  .service-text-wrapper h3 {
    font-size: 1.5em;
  }
  .service-text-wrapper p {
    font-size: 1.5em;
  }
  .service-text-wrapper .text-link {
    font-size: 0.9em;
  }
  .service-text-wrapper #gallery-anchor {
    padding-top: calc(5vw + 2em);
  }
  .gallery-container .gallery-img-container {
    width: 33.3333333333%;
    height: 33.3333333333%;
  }
}
@media only screen and (min-width: 1200px) {
  .service-head-wrapper {
    width: 100%;
    max-width: 1800px;
  }
  .service-head-wrapper .image-pusher {
    height: calc(4.5vw + 2em);
    max-height: 6.7em;
  }
  .service-head-wrapper .service-head-img {
    width: 100%;
    height: calc(100vh - min(5vw + 2em, 6.5em));
  }
  .service-head-wrapper .header-logo-wrapper {
    max-width: 1800px;
  }
  .service-head-wrapper .header-logo-wrapper .text-link {
    font-size: 1em;
    margin-right: 2em;
  }
  .service-head-wrapper .header-logo-wrapper #hypersquare-logo {
    width: 25vw;
    max-width: 396px;
    margin: 1.5em 2em 1.5em 2em;
  }
  .service-text-wrapper {
    width: 100vw;
    max-width: 1800px;
    padding: 1.5em 2em 0 2em;
    margin: 0;
    margin: auto;
  }
  .service-text-wrapper h1 {
    font-size: 3.5em;
    padding-top: calc(6vw + 3em + 5vh);
  }
  .service-text-wrapper .project-subheadline {
    font-size: 1.2em;
  }
  .service-text-wrapper .intro-text,
  .service-text-wrapper .main-text {
    width: 60%;
    max-width: 990px;
  }
  .service-text-wrapper .intro-text p {
    font-size: 1.725em;
  }
  .service-text-wrapper h2 {
    font-size: 2.4em;
  }
  .service-text-wrapper h3 {
    font-size: 1.5em;
  }
  .service-text-wrapper p {
    font-size: 1.5em;
  }
  .service-text-wrapper #gallery-anchor {
    padding-top: min(6.5em, 5vw + 2em);
  }
  .gallery-container .gallery-img-container {
    width: 25%;
    height: 25%;
  }
}/*# sourceMappingURL=ServiceDetails.css.map */