.project-wrapper {
  width: inherit;
  margin: 0 0 1em 0;
  display: grid;
  overflow: visible;
  padding-bottom: 1em;
  grid-auto-rows: 1fr;
}
.project-wrapper :link,
.project-wrapper :visited {
  color: white;
  text-decoration: none;
}
.project-wrapper :link:hover {
  color: white;
}
.project-wrapper .project-card-link {
  overflow: visible;
  padding: 0;
  height: 100%;
}
.project-wrapper .project-card-link .project-card-wrapper {
  position: relative;
  transition: ease-out 200ms;
  background-color: #17171A;
  overflow: visible;
  height: 100%;
}
.project-wrapper .project-card-link .project-card-wrapper .project-card {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden;
}
.project-wrapper .project-card-link .project-card-wrapper .project-card .project-card-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
  aspect-ratio: 1/1;
}
@supports not (aspect-ratio: 1/1) {
  .project-wrapper .project-card-link .project-card-wrapper .project-card .project-card-image::before {
    float: left;
    padding-top: 100%;
    content: "";
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card .project-card-image::after {
    display: block;
    content: "";
    clear: both;
  }
}
.project-wrapper .project-card-link .project-card-wrapper .project-card-text {
  padding: 0;
  overflow: hidden;
}
.project-wrapper .project-card-link .project-card-wrapper .project-card-text h2 {
  font-weight: 400;
  letter-spacing: 0.2em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.project-wrapper .project-card-link .project-card-wrapper .project-card-text h3 {
  font-weight: 400;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: 0.1em;
}
.project-wrapper .project-card-link .project-card-wrapper .project-card-text div {
  padding: 0;
  -webkit-hyphens: auto;
          hyphens: auto;
}
.project-wrapper .project-card-link .project-card-wrapper .text-link {
  position: absolute;
  overflow: hidden;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8em;
  letter-spacing: 0.1em;
  color: rgb(255, 238, 0);
  text-decoration: none;
  text-align: right;
  bottom: 0;
  right: 0;
  padding: 0.2em 0.75em 0.2em 1.5em;
  line-height: 2.5em;
  border-radius: 0 0 0 0;
}
.project-wrapper .project-card-link .project-card-wrapper .bi {
  display: inline;
  vertical-align: middle;
  font-size: 220%;
  padding: 0;
}
.project-wrapper .project-card-link .project-card-wrapper:hover .text-link {
  background-color: rgb(255, 238, 0);
  color: #17171A;
  transform: translateX(0.75em);
}
.project-wrapper .project-card-link .project-card-wrapper:hover .project-card-image {
  transform: scale(1.01);
}
.project-wrapper .project-card-link .project-card-wrapper:active .text-link {
  background-color: #ffffff;
  color: #17171A;
}
.project-wrapper .project-card-link:hover {
  filter: drop-shadow(0.1em 0.1em 0.5em rgba(0, 0, 0, 0.5));
  transform: scale(1.01);
}
.project-wrapper .project-card-wrapper:hover .project-card .project-card-image {
  filter: brightness(1.1);
}
.project-wrapper .project-card-wrapper:hover {
  background-color: rgba(23, 23, 26, 0.5);
}

@media only screen and (max-width: 475px) {
  .project-wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 1fr;
    grid-row-gap: 2em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card-text {
    margin: 1em 1em 1.5em 1.2em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card-text h2 {
    font-size: 1.2em;
    line-height: 1.15em;
    width: calc(100% - 2.2em);
    margin-bottom: 0.5em;
    letter-spacing: 0.7vw;
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card-text h3 {
    font-size: 0.72em;
    margin-bottom: 1.2em;
    letter-spacing: 0.5vw;
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card-text div {
    font-size: 0.96;
    margin-bottom: 2.4em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .text-link {
    font-size: 0.84em;
  }
}
@media only screen and (min-width: 475px) {
  .project-wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    margin: 1em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card-text {
    margin: 1em 1em 1.5em 1em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card-text h2 {
    font-size: 1.2em;
    line-height: 1.1em;
    width: calc(100% - 2em);
    margin-bottom: 0.5em;
    letter-spacing: 0.12vw;
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card-text h3 {
    font-size: 0.72em;
    margin-bottom: 1.2em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card-text div {
    font-size: 0.96em;
    margin-bottom: 2.4em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .text-link {
    font-size: 0.84em;
  }
}
@media only screen and (min-width: 650px) {
  .project-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    margin: 1em;
  }
}
@media only screen and (min-width: 900px) {
  .project-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 1.5em;
    grid-row-gap: 1.5em;
    margin: 1.5em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card-text {
    margin: 1em 1em 1.5em 1.2em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card-text h2 {
    font-size: 1.3em;
    line-height: 1.2em;
    margin-bottom: 0.5em;
    letter-spacing: 0.2em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card-text h3 {
    font-size: 0.78em;
    margin-bottom: 1.3em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card-text div {
    font-size: 1.04em;
    margin-bottom: 2.6em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .text-link {
    font-size: 0.91em;
  }
}
@media only screen and (min-width: 1200px) {
  .project-wrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    margin: 2em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card-text {
    margin: 1em 1em 1.5em 1.2em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card-text h2 {
    font-size: 1.6em;
    line-height: 1.2em;
    margin-bottom: 0.5em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card-text h3 {
    font-size: 0.96em;
    margin-bottom: 1.6em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .project-card-text div {
    font-size: 1.28em;
    margin-bottom: 3.2em;
  }
  .project-wrapper .project-card-link .project-card-wrapper .text-link {
    font-size: 1.12em;
  }
}/*# sourceMappingURL=ProjectCard.css.map */