.App {
  color: #ffffff;
  padding: 0;
  margin: 0;
}

.main-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  overflow: hidden;
}

.project-card {
  margin-top: 1000px;
}

@media only screen and (max-width: 475px) {
  .main-container {
    min-width: 360px;
  }
}
@media only screen and (min-width: 475px) {
  .main-container {
    width: auto;
    padding: 0 4vw 0 4vw;
  }
}
@media only screen and (min-width: 1200px) {
  .main-container {
    width: auto;
    max-width: 1800px;
    padding: 0 5vw 0 5vw;
  }
}/*# sourceMappingURL=App.css.map */