.hero-container {
  position: relative;
  padding: 0;
  width: 100%;
  height: 100vh;
  background-color: #17171A;
  margin: 0 auto;
  overflow: hidden;
}

.logo-container {
  transition: ease-out 400ms;
  height: 100vh;
}

.logo-wrapper {
  position: absolute;
  z-index: 20;
}

.logo-svg {
  fill: white;
  filter: drop-shadow(0.05em 0.05em 0.5em rgba(0, 0, 0, 0.5));
  transition: fill ease 700ms;
}

.deactivate-logo {
  fill: rgba(255, 255, 255, 0);
}

.logo-subtitle {
  color: white;
  cursor: default;
  filter: drop-shadow(0.05em 0.05em 0.5em rgba(0, 0, 0, 0.5));
  transition: color ease 700ms;
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}
.logo-subtitle p {
  transition: none;
}

.deactivate-subtitle {
  color: rgba(255, 255, 255, 0);
}

.video-overlay-bg {
  content: "";
  position: absolute;
  padding: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(5deg, rgba(0, 0, 0, 0.92), rgba(40, 39, 43, 0.15));
  z-index: 5;
  transition: opacity ease 700ms;
}

.deactivate-overlay {
  opacity: 0;
}

.video-overlay {
  content: "";
  position: absolute;
  padding: 0;
  width: 400%;
  height: 400%;
  z-index: 6;
  mix-blend-mode: screen;
  transition: ease 0ms;
  transition: opacity ease 700ms;
}

.hero-video {
  padding: 0;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  z-index: 0;
  transition: ease-out 0ms;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  transform: translateX(calc((100% - 100vw) / 2));
}

.button-wrapper {
  width: auto;
}

.mute-button {
  position: absolute;
  z-index: 30;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1em;
  font-weight: 500;
  color: #ffffff;
  background-color: transparent;
  border: 0.15em solid rgba(255, 255, 255, 0.7);
  display: block;
  white-space: nowrap;
  clear: both;
  cursor: pointer;
  line-height: 2.5em;
  filter: drop-shadow(0.05em 0.05em 0.5em rgba(0, 0, 0, 0.5));
  transition: ease 150ms;
  font-size: 0.8em;
  padding: 0.4em 0 0.4em 0;
  width: 16em;
  margin: 2.4em;
}
.mute-button .bi {
  padding: 0;
  display: inline;
  vertical-align: middle;
  font-size: 220%;
}
.mute-button .left-icon {
  font-size: 150%;
  vertical-align: middle;
  padding-left: 0.2em;
  padding-right: 0.5em;
}

.mute-button:hover {
  color: white;
  border-color: white;
  filter: drop-shadow(0.05em 0.05em 0.5em rgba(0, 0, 0, 0.9));
  background-color: rgba(0, 0, 0, 0.25);
}

.cloak-button {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 475px) {
  .video-overlay-bg {
    background: linear-gradient(5deg, rgba(40, 39, 43, 0.15), rgba(0, 0, 0, 0.92));
  }
  .video-overlay {
    background: linear-gradient(transparent 5px, #000000 5px, #000000 7.75em, transparent 7.75em), linear-gradient(-90deg, rgba(255, 255, 255, 0.2) 1px, transparent 1px), linear-gradient(-90deg, transparent 5px, #000000 5px, #000000 7.75em, transparent 7.75em), linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px), #000000;
    background-size: 8em 8em, 8em 8em, 8em 8em, 8em 8em;
  }
  .mute-button {
    font-size: 0.7em;
    padding: 0.4em 0 0.4em 0;
    width: 16em;
    margin: 0;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 9em;
  }
  .deactivate-logo {
    fill: rgba(255, 255, 255, 0);
  }
  .deactivate-subtitle {
    color: rgba(255, 255, 255, 0);
  }
  .logo-wrapper {
    width: 60%;
    min-width: 360px;
    margin: 7% 0 0 0.5%;
  }
  #logo {
    width: 45%;
    height: auto;
    margin-top: -4%;
  }
  #lettering-1 {
    width: 70%;
    height: auto;
    margin-top: -1%;
    margin-left: 6.5%;
  }
  #lettering-2 {
    width: 85%;
    height: auto;
    margin-top: 3%;
    margin-left: 6.5%;
  }
  .logo-subtitle {
    margin-top: 3.25%;
    margin-left: 6.5%;
  }
}
@media only screen and (min-width: 475px) {
  .video-overlay {
    background: linear-gradient(transparent 5px, #000000 5px, #000000 7.75em, transparent 7.75em), linear-gradient(-90deg, rgba(255, 255, 255, 0.2) 1px, transparent 1px), linear-gradient(-90deg, transparent 5px, #000000 5px, #000000 7.75em, transparent 7.75em), linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px), #000000;
    background-size: 8em 8em, 8em 8em, 8em 8em, 8em 8em;
  }
  .mute-button {
    font-size: 0.7em;
    padding: 0.35em 0 0.35em 0;
    width: 16em;
    margin: 0;
    margin-top: calc(5em + 8vh);
    left: 50%;
    transform: translate(-50%, 0);
  }
  .logo-wrapper {
    position: relative;
    margin-left: 4%;
    margin-top: 70vh;
    vertical-align: middle;
    display: flex;
  }
  #logo {
    width: 17%;
    height: auto;
  }
  #lettering-1 {
    width: 30vw;
    height: auto;
    padding-left: 1%;
    display: block;
  }
  #lettering-2 {
    width: 37vw;
    height: auto;
    display: block;
  }
  .logo-subtitle {
    display: inline-block;
    flex-direction: row;
    margin: 5.5em 0 0 -30.35em;
    font-size: 2.185vw;
  }
  br {
    display: none;
  }
}
@media only screen and (min-width: 900px) {
  .mute-button {
    font-size: 0.8em;
    padding: 0.4em 0 0.4em 0;
    width: 16em;
    margin: 2.4em;
    bottom: auto;
    right: auto;
    left: auto;
    transform: none;
  }
}
@media only screen and (min-width: 1200px) {
  .video-overlay {
    background: linear-gradient(transparent 5px, #000000 5px, #000000 9.75em, transparent 9.75em), linear-gradient(-90deg, rgba(255, 255, 255, 0.2) 1px, transparent 1px), linear-gradient(-90deg, transparent 5px, #000000 5px, #000000 9.75em, transparent 9.75em), linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px), #000000;
    background-size: 10em 10em, 10em 10em, 10em 10em, 10em 10em;
  }
  .mute-button {
    font-size: 0.8em;
    padding: 0.4em 0 0.4em 0;
    width: 16em;
    margin: 2.75em;
  }
  .deactivate-logo {
    fill: rgba(255, 255, 255, 0.3);
  }
  .deactivate-subtitle {
    color: rgba(255, 255, 255, 0.3);
  }
  .logo-wrapper {
    margin: calc(100vh - 16%) 0 0 3%;
  }
  #logo {
    width: 13%;
    height: auto;
    padding-left: 1.5em;
  }
  #lettering-1 {
    width: 20vw;
    height: auto;
    padding-left: 0.75%;
  }
  #lettering-2 {
    width: 24.5vw;
  }
  .logo-subtitle {
    margin: calc(6% + 1.7em) 0 0 -30.3em;
    font-size: 1.45vw;
  }
}/*# sourceMappingURL=HeroSection.css.map */