.footer-section {
  position: relative;
  height: 100vh;
  min-width: 360px;
  overflow: hidden;
  margin-top: 1px;
}

.contact-area {
  position: absolute;
  bottom: 0;
  width: 100vw;
  min-width: 360px;
  background-color: #17171A;
  transition: ease 200ms;
}
.contact-area .contact-text-wrapper br {
  display: inline-block;
}
.contact-area .contact-text-wrapper h1 {
  font-weight: 400;
  letter-spacing: 0.15em;
}
.contact-area .contact-text-wrapper p {
  font-style: italic;
  line-height: 1.4em;
}

.std-button {
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1em;
  font-weight: 600;
  color: #28272B;
  background-color: rgb(255, 238, 0);
  display: block;
  white-space: nowrap;
  clear: both;
  cursor: pointer;
  padding: 0.5em 1.7em 0.5em 1.5em;
  line-height: 2.5em;
  margin-top: 1em;
}
.std-button .bi {
  padding: 0;
  display: inline;
  vertical-align: middle;
  font-size: 220%;
}
.std-button .left-icon {
  font-size: 150%;
  vertical-align: middle;
  padding-left: 0.2em;
  padding-right: 0.5em;
}

.std-button:hover {
  color: #28272B;
  background-color: rgb(255, 255, 122);
}

.std-button:active {
  background-color: #ffffff;
}

.footer-links {
  padding: 2.5em 0 2.8em 0;
}
.footer-links .social-icons {
  display: inline;
  font-size: 220%;
  padding: 0;
  vertical-align: middle;
  margin-right: 0.5em;
  cursor: pointer;
  transition: ease 150ms;
}
.footer-links .social-icons:hover {
  color: rgb(255, 255, 122);
}
.footer-links .social-icons:active {
  color: #ffffff;
}
.footer-links .footer-nav-wrapper {
  padding-top: 1em;
}
.footer-links .footer-nav-wrapper .footer-nav,
.footer-links .footer-nav-wrapper .footer-nav:link {
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1em;
  font-weight: 500;
  transition: ease 150ms;
  color: #ffffff;
  margin-top: 3em;
  font-size: 0.6em;
}
.footer-links .footer-nav-wrapper .divider {
  opacity: 0.5;
  margin: 0 0.5em 0 0.5em;
}
.footer-links .footer-nav-active:hover,
.footer-links .footer-nav-active:link:hover {
  color: rgb(255, 255, 122);
}
.footer-links .footer-nav-active:link:active {
  color: #ffffff;
}

@media only screen and (max-width: 475px) {
  .footer-section {
    height: 810px;
  }
  .contact-area {
    width: 100%;
    min-width: 100%;
    padding: 1.5em;
  }
  .contact-area .contact-text-wrapper {
    width: 100%;
    padding-bottom: 0.5em;
  }
  .contact-area .contact-text-wrapper h1 {
    font-size: 1.92em;
  }
  .contact-area .contact-text-wrapper p {
    margin: 1em 0 1em 0;
    font-size: 1.2em;
  }
  .std-button {
    font-size: 0.65em;
  }
  .footer-links {
    margin-bottom: 2em;
  }
}
@media only screen and (min-width: 475px) {
  .contact-area {
    width: 100%;
    padding: 4vw 4vw 0 4vw;
  }
  .contact-area .contact-text-wrapper {
    min-width: 360px;
    padding-bottom: 0.5em;
  }
  .contact-area .contact-text-wrapper h1 {
    width: 100%;
    font-size: 1.92em;
  }
  .contact-area .contact-text-wrapper p {
    width: 90%;
    max-width: 540px;
    font-size: 1.2em;
    margin: 2em 0 1em 0;
  }
  .std-button {
    font-size: 0.7em;
  }
}
@media only screen and (min-width: 900px) {
  .contact-area {
    width: 100%;
    padding: 4vw 4vw 0 4vw;
  }
  .contact-area .contact-text-wrapper {
    padding-bottom: 0.5em;
  }
  .contact-area .contact-text-wrapper h1 {
    width: 90%;
    font-size: 2.08em;
  }
  .contact-area .contact-text-wrapper p {
    width: 70%;
    font-size: 1.3em;
    margin: 2em 0 1em 0;
  }
  .std-button {
    font-size: 0.7em;
  }
}
@media only screen and (min-width: 1200px) {
  .contact-area {
    width: 100%;
    padding: 5vw 5vw 0 5vw;
  }
  .contact-area .contact-text-wrapper {
    padding-bottom: 0.5em;
  }
  .contact-area .contact-text-wrapper h1 {
    width: 80%;
    font-size: 2.24em;
  }
  .contact-area .contact-text-wrapper p {
    width: 55%;
    max-width: 684px;
    font-size: 1.4em;
    margin: 2em 0 1em 0;
  }
  .std-button {
    font-size: 0.7em;
  }
}/*# sourceMappingURL=FooterSection.css.map */