.service-section {
  margin-top: 0;
}

.service-head {
  min-width: calc(360px - 4em);
}
.service-head br {
  display: inline-block;
}
.service-head video {
  pointer-events: none;
  mix-blend-mode: normal;
  border-radius: 50%;
  -webkit-mask-image: radial-gradient(black 45%, rgba(0, 0, 0, 0));
  mask-image: radial-gradient(black 45%, rgba(0, 0, 0, 0));
}
.service-head .intro-subtitle {
  color: rgb(255, 238, 0);
  font-weight: 500;
  letter-spacing: 0.1em;
}
.service-head h1 {
  font-weight: 400;
  letter-spacing: 0.15em;
}
.service-head p {
  font-style: italic;
  line-height: 1.4em;
}

@media only screen and (max-width: 475px) {
  .service-head {
    padding: 13vh 2em 13vh 2em;
  }
  .service-head video {
    width: 80%;
    display: block;
    margin: auto;
    margin-bottom: 2em;
  }
  .service-head .intro-subtitle {
    font-size: 0.84em;
  }
  .service-head h1 {
    font-size: 2.24em;
    margin-top: 0.2em;
    margin-bottom: 1em;
    max-width: 90%;
  }
  .service-head p {
    font-size: 1.4em;
    hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
  }
}
@media only screen and (min-width: 475px) {
  .service-head {
    padding: 20vh 0 13vh 0;
    margin: 0 auto;
  }
  .service-head video {
    width: 65%;
    max-width: 360px;
    display: block;
    margin: auto;
    margin-bottom: 2em;
  }
  .service-head .intro-subtitle {
    font-size: 0.84em;
  }
  .service-head h1 {
    font-size: 2.24em;
    margin-top: 0.2em;
    margin-bottom: 1em;
  }
  .service-head p {
    font-size: 1.4em;
  }
}
@media only screen and (min-width: 900px) {
  .service-head {
    padding: 25vh 0 18vh 0;
    margin: 0 auto;
  }
  .service-head video {
    display: inline-block;
    margin: auto;
    vertical-align: middle;
    width: 40%;
  }
  .service-head .text-wrapper {
    display: inline-block;
    vertical-align: middle;
    padding-left: 1em;
    width: 60%;
  }
  .service-head .intro-subtitle {
    font-size: 0.9em;
  }
  .service-head h1 {
    font-size: 2.1em;
    margin-top: 0.2em;
    margin-bottom: 1em;
  }
  .service-head p {
    font-size: 1.5em;
  }
}
@media only screen and (min-width: 1200px) {
  .service-head {
    padding: 28vh 0 18vh 0;
    margin: 0 auto;
  }
  .service-head video {
    width: 30%;
    max-width: 30%;
  }
  .service-head .text-wrapper {
    padding-left: 1.5em;
    width: 70%;
  }
  .service-head .intro-subtitle {
    font-size: 1.08em;
  }
  .service-head h1 {
    font-size: 2.88em;
    margin-top: 0.24em;
    margin-bottom: 1em;
  }
  .service-head p {
    font-size: 1.8em;
    max-width: 95%;
  }
}/*# sourceMappingURL=ServiceSection.css.map */