@import "../styles/variables.scss";

.legal-notice-detail {
  background: rgba($bg-color-dark, .8);
  padding-bottom: 5em;
  min-height: 100vh;

  h2 {
    text-transform: none;
    padding-top: 1em;    
    font-weight: 600;
  }

  h3 {
    text-transform: none;
    padding-top: 2em;
    font-weight: 600;
  }

  p{
    padding-top: .5em;
  }

}

@media only screen and (max-width: $break-sm) {
  .legal-notice-detail {
    h1 {
      padding-top: calc(5vw + 2em);
    }
  }
}

@media only screen and (min-width: $break-sm) {
  .legal-notice-detail {
    h1 {
      padding-top: calc(5vw + 2em);
    }
  }
}

@media only screen and (min-width: $break-md) {
  .legal-notice-detail {

    h1 {
      padding-top: calc(5vw + 2em);
    }

  }
}

@media only screen and (min-width: $break-lg) {
  .legal-notice-detail {

    h1 {
      padding-top: calc(5vw + 2em);
    }

  }
}